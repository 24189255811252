* {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
  }
  
  /* body {
    background: #ffccbc;
  } */
  
  /* .action {
    position: fixed;
    top: 20px;
    right: 30px;
  } */
  
  .action .profile {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  
  .action .profile img {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 80%;
    object-fit: cover;
  }
  
  .action .menu {
    position: absolute;
    top: 120px;
    right: -10px;
    padding: 10px 20px;
    background: #fff;
    width: 200px;
    box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
    z-index: 111;
  }
  
  .action .menu.active {
    top: 80px;
    visibility: visible;
    opacity: 1;
  }
  
  .action .menu::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 28px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
    z-index: 111;
  }
  
  .action .menu h3 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding: 20px 0;
    font-weight: 500;
    color: #555;
    line-height: 1.5em;
  }
  
  .action .menu h3 span {
    font-size: 14px;
    color: #cecece;
    font-weight: 300;
  }
  
  .action .menu ul li {
    list-style: none;
    padding: 16px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
  }
  
  .action .menu ul li i {
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: 0.5s;
  }
  
  .action .menu ul li:hover i {
    opacity: 1;
  }
  
  .action .menu ul li a {
    display: inline-block;
    text-decoration: none;
    color: #555;
    font-weight: 500;
    transition: 0.5s;
  }
  
  .action .menu ul li:hover a {
    color: #2e55fa;
  }
  
@import url(http://fonts.googleapis.com/css?family=Open+Sans:700,600,800,400);
/* @import url(http://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css); */

.ui {
  /* margin: 10px auto; */
  /* width: 745px; */
  height: 100vh;
  /* background-color: #fff; */
  /* border-radius: 3px; */
  /* box-shadow: 0 0 25px #3a9fc4; */
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
}
.ui .search input {
  outline: none;
  border: none;
  background: none;
}
.ui .search {
  position: relative;
}
.ui .search input[type=submit] {
  font-family: 'FontAwesome';
  position: absolute;
  right: 25px;
  top: 27px;
  color: white;
}
.ui .search input[type=search] {
  background-color: #696c75;
  border-radius: 3px;
  padding: 10px;
  width: 90%;
  box-sizing: border-box;
  margin: 15px 10px;
  color: #fff;
}
.ui .left-menu {
  width: 29%;
  box-sizing: content-box;
  padding-right: 1%;
  /* height: 100%; */
  background: #434753;
}
.ui .chat {
  width: 100%;
  height: 94%;
  background: #f1f5f8;
}
.ui .chat .info {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  vertical-align: 40px;
  width: calc(100% - 65px - 50px);
}
.ui .chat .info .name {
  font-weight: 600;
  color: #fff;
  height: 50%;
}
.ui .chat .info .count {
  color: #ddd;
}
.ui .chat i.fa {
  color: #d6d9de;
  vertical-align: 25px;
}
.ui .avatar > img,
.ui .list-friends img {
  border-radius: 50%;
  border: 3px solid #696c75;
}
.ui .list-friends {
  list-style: none;
  font-size: 13px;
  height: 88%;
}
.ui .list-friends img {
  margin: 5px;
}
.ui .list-friends > li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 10px 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ui .list-friends .info {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.ui .list-friends .user {
  color: #fff;
  margin-top: 12px;
}
.ui .list-friends .status {
  position: relative;
  margin-left: 14px;
  color: #a8adb3;
}
.ui .list-friends .off:after,
.ui .list-friends .on:after {
  content: '';
  left: -12px;
  top: 8px;
  position: absolute;
  height: 7px;
  width: 7px;
  border-radius: 50%;
}
.ui .list-friends .off:after {
  background: #fd8064;
}
.ui .list-friends .on:after {
  background: #62bf6e;
}
.ui .top {
  height: 70px;
  display: flex;
  align-items: center;
  background: #3396d1;
  box-shadow: 2px 2px 2px rgba(0,0,0,.1),
   2px 2px 2px #3396d1
  ;
  border-radius: 2px;
}
.msg-ui{
  height:  calc(100% - 70px - 150px);
  overflow-y: scroll;
  overflow-x: hidden;
  /* background-color: #62bf6e; */
}
.ui .messages {
  height: calc(100% - 70px - 150px);
  list-style: none;
  border: 2px solid #fff;
  border-left: none;
  border-right: none;
  background-color: #62bf6e;
}
.msg-ui li {
  list-style: none;
  margin: 10px;
  -webkit-transition: all .5s;
  transition: all .5s;
}
.msg-ui li:after {
  content: '';
  clear: both;
  display: block;
}
.msg-ui li .head {
  font-size: 13px;
}
.msg-ui li .name {
  font-weight: 600;
  position: relative;
}
.msg-ui li .name:after {
  content: '';
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  top: 6px;
}
.msg-ui li .time {
  color: #b7bccf;
    margin-right: 20px;
    margin-left: 20px;
}
.msg-ui li .message {
  margin-top: 20px;
  color: #fff;
  font-size: 15px;
  border-radius: 3px;
  padding: 20px;
  line-height: 25px;
  max-width: 500px;
  word-wrap: break-word;
  position: relative;
}
.msg-ui li .message:before {
  content: '';
  position: absolute;
  width: 0px;
  height: 0px;
  top: -12px;
  border-bottom: 12px solid #62bf6e;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.msg-ui li.friend-with-a-SVAGina .name {
  margin-left: 20px !;
}
.msg-ui li.friend-with-a-SVAGina .name:after {
  background-color: #62bf6e;
  left: -20px;
  top: 6px;
}
.msg-ui li.friend-with-a-SVAGina .message {
  background-color: #62bf6e;
  float: left;
}
.msg-ui li.friend-with-a-SVAGina .message:before {
  left: 16px;
  border-bottom-color: #62bf6e;
}
.msg-ui li.i .head {
  text-align: right;
 
}
.msg-ui li.i .name {
  margin-right: 20px !important;
}
.msg-ui li.i .name:after {
  background-color: #7bc4ef;
  right: -20px;
  top: 6px;
}
.msg-ui li.i .message {
  background-color: #7bc4ef;
  float: right;
}
.msg-ui li.i .message:before {
  right: 16px;
  border-bottom-color: #7bc4ef;
}
.ui .write-form {
  /* height: 150px; */
}
.ui .write-form .preview{
  margin: 0px 5%;
  display: flex;
  align-items: center;
  background-color: cornflowerblue;
  border-radius: 50px;
}
.ui .write-form .preview img{
  width: 60px;
  border-radius: 50%;
}
.ui .write-form .preview p{
  color: #fff;
  margin-left: 10px;
  margin-top: 10px;
}
.ui .write-form textarea {
  height: 75px;
  margin: 0px 5%;
  width: 90%;
  outline: none;
  padding: 15px;
  border: none;
  border-radius: 3px;
  resize: none;
}
.ui .write-form textarea:before {
  content: '';
  clear: both;
}
.ui .avatar > img {
  border-color: #62bf6e;
  margin: 10px;
  margin-right: 5px;
}
.ui .avatar {
  display: inline-block;
}
.ui .send {
  text-transform: uppercase;
  font-weight: 700;
  float: right;
  margin-right: 5%;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
          border-radius: 120px;
          padding: 10px 16px 10px 16px;
          background: #7ac5ef;
          color: #fff;
}
.ui i.fa-file-o {
  margin-left: 15px;
}
.ui i.fa-picture-o {
  margin-left: 5%;
}

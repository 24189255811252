
@keyframes ldio-1r1g1o9j93t-1 {
  0% { top: 36px; height: 128px }
  50% { top: 60px; height: 80px }
  100% { top: 60px; height: 80px }
}
@keyframes ldio-1r1g1o9j93t-2 {
  0% { top: 41.99999999999999px; height: 116.00000000000001px }
  50% { top: 60px; height: 80px }
  100% { top: 60px; height: 80px }
}
@keyframes ldio-1r1g1o9j93t-3 {
  0% { top: 48px; height: 104px }
  50% { top: 60px; height: 80px }
  100% { top: 60px; height: 80px }
}
.ldio-1r1g1o9j93t div { position: absolute; width: 30px }.ldio-1r1g1o9j93t div:nth-child(1) {
  left: 35px;
  background: #3396d1;
  animation: ldio-1r1g1o9j93t-1 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.2s
}
.ldio-1r1g1o9j93t div:nth-child(2) {
  left: 85px;
  background: #3396d1;
  animation: ldio-1r1g1o9j93t-2 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.1s
}
.ldio-1r1g1o9j93t div:nth-child(3) {
  left: 135px;
  background: #3396d1;
  animation: ldio-1r1g1o9j93t-3 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: undefineds
}

.loadingio-spinner-pulse-xys3s4ogjj {
  width: 200px;
  height: 80px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-1r1g1o9j93t {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-1r1g1o9j93t div { box-sizing: content-box; }
